export function useSeoLangLink({canonical=true}={}) {
  const {
    public: { HOST },
  } = useRuntimeConfig();
  const { locale, locales } = useI18n();
  const route = useRoute();
  const link = <any>locales.value.map((v: any) => {
    let href = "";
    if (locale.value === "en") {
      if (v.code === "en") {
        href = route.path;
      } else {
        href = "/" + v.code + route.path;
      }
      return {
        rel: "alternate",
        href: HOST + href,
        hreflang: v.language,
      };
    } else {
      href = route.path.replace(
        "/" + locale.value,
        v.code == "en" ? "" : "/" + v.code
      );
      return {
        rel: "alternate",
        href: HOST + href,
        hreflang: v.language,
      };
    }
  });
  let defaultHref = "";
  if (locale.value === "en") {
    defaultHref = HOST + route.path;
  } else {
    defaultHref = HOST + route.path.replace("/" + locale.value, "");
  }
  link.push({
    rel: "alternate",
    href: defaultHref,
    hreflang: "x-default",
  });
  if(canonical){
    link.push({
      rel: "canonical",
      href: HOST + route.path,
    });
  }
//   const index = link.findIndex((v: {
//     rel: string; href: string; 
// }) => v.rel=="alternate"&&v.href.includes('zh-Hant'))
//   if (index > -1) {
//     link.splice(index,1)
//   }
  // console.log('link',index,link);
  return link;
}
